import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
// import ProjectCard from "./ProjectCards";

// import PrivateChat from "../../Assets/Projects/PrivateChat.png";
// import Portfolio from "../../Assets/Projects/Portfolio.png";
// import Ecart from "../../Assets/Projects/ecart.png";
// import ExpenseTracker from "../../Assets/Projects/ExpenseTracker.png";

import "./project.css";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="Fluorescent-Blue">Works </strong>
        </h1>
        <p>Here are a few projects I've worked on recently.</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={6} lg={4} className="project-card">
            {/* <ProjectCard
              imgPath={Ecart}
              title="E Cart"
              description="This is a fully functional eCommerce website that uses React.js + MUI and CSS in the front end and NodeJs and express js in backend and MongoDb as Database."
              ghLink="https://github.com/samuvelAnbudavid/E-cart"
              demoLink="https://ecart.onrender.com/"
            /> */}
          </Col>

          <Col md={6} lg={4} className="project-card">
            {/* <ProjectCard
              imgPath={Portfolio}
              title="Portfolio Website"
              description="My personal Portfolio Website build with React and Bootstrap. It is fully responsive website which supports both dark and light mode."
              ghLink="https://github.com/samuvelAnbudavid/Portfolio-Website"
              demoLink="https://rahuljha.tech/"
            /> */}
          </Col>
          <Col md={6} lg={4} className="project-card">
            {/* <ProjectCard
              imgPath={PrivateChat}
              title="Private Chat"
              description="A Personal Chat Application to share resources and hangout with friends build with react.js, css, and Firebase. Have features which allows user for realtime messaging, image sharing and search user."
              ghLink="#"
              demoLink="https://chat-app-samuvelAnbudavid.vercel.app/"
            /> */}
          </Col>

          <Col md={6} lg={4} className="project-card">
            {/* <ProjectCard
              imgPath={ExpenseTracker}
              title="Expense Tracker"
              description="An online expense tracker using React, Node, Express, and MongoDB as the database. It allows you to keep track of your expenses, investments, and savings. All past transactions are listed, and you can also delete them. "
              ghLink="https://github.com/samuvelAnbudavid/Expense-Tracker"
              demoLink="https://expense-tracker.samuvelAnbudavid.vercel.app/"
            /> */}
          </Col>
        </Row>
      </Container>
      <ScrollToTop />
    </Container>
  );
}

export default Projects;
